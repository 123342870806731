import { TableHeadCell } from "../../../shared/models/TableModels";

export enum WorkflowConstants {
  WORKFLOW_TITLE_LENGTH = 32,
  WORKFLOW_DESCRIPTION_LENGTH = 120,
  VIEW_MORE_TEXT = "view more",
  VIEW_LESS_TEXT = "view less",
  ASSIGN = "Assign",
  SHARE = "Share",
  TRACK_PROGRESS = "Track Progress",
  VERSION_HISTORY = "Version History",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  TOTAL_WORKFLOW_COUNT = "Total Workflows: ",
  NO_DATA_AVAILABLE = "No workflow data available.",
  NO_WORKFLOW_HISTORY_AVAILABLE = "No workflow history data available.",
  WORKFLOW_ASSIGNED_GROUP_SUCCESS_MSG = "Workflow assigned to group successfully.",
  ASSIGNED_WORKFLOW_GROUP_DELETE_MSG = "Assigned workflow from group removed successfully.",
  WORKFLOW_SHARED_SUCCESS_MSG = "Workflow has been shared successfully.",
  WORKFLOW_CLONED_SUCCESS_MSG = "Workflow has been copied successfully.",
  WORKFLOW_PUBLISHED_SUCCESS_MSG = "Workflow has been published successfully.",
  UPDATE_WORKFLOW_SUCCESS_MSG = "Workflow has been updated successfully.",
  UPDATE_STEP_SUCCESS_MSG = "Step has been updated successfully.",
  DELETE_STEP_SUCCESS_MSG = "Step has been deleted successfully.",
  WORKFLOW_ENABLED_SUCCESS_MSG = "Workflow has been enabled successfully.",
  WORKFLOW_DISABLED_SUCCESS_MSG = "Workflow has been disabled successfully.",
  WORKFLOW_SAVED_SUCCESS_MSG = "Workflow has been saved successfully.",
  WORKFLOW_SUBMIT_REVIEW_SUCCESS_MSG = "Workflow has been submitted for review successfully.",
  WORKFLOW_SHARE_EMAIL_MAX_LIMIT_REACHED = "You can share workflow with maximum 5 email ids.",
}

export const WorkflowStatusConstants = {
  DRAFT: "Draft",
  WORK_IN_PROGRESS: "In Progress",
  IN_REVIEW: "Ready for review",
};

export interface CollapseRowProps {
  workflowId: number;
  workflowData?: WorkflowListModel;
  switchTabName?: (tabName?: WorkflowConstants) => void;
  collapseClose: () => void;
}

export interface WorkflowVersionsModel {
  id: number;
  versionNumber: number;
  status: string;
  steps: number;
  createdAt: string;
  modifiedAt: string;
}
export interface WorkflowListModel {
  id: number;
  name: string;
  description: string;
  noOfVersions: number;
  publishedVersion: number;
  groupsAssigned: number;
  createdAt: string;
  modifiedAt: string;
  inactive: boolean;
  workflowVersions: WorkflowVersionsModel[];
  isDescriptionViewMore?: boolean;
}

export interface WorkflowAssignGroupsModel {
  id: number;
  name: string;
  progress: number;
  usersAssigned: number;
  workflowAssigned: number;
  workflowViewed: string;
}

export interface WorkflowGroupsModel {
  id: number;
  name: string;
  location: string;
  createdAt: string;
  modifiedAt: string;
}

export const WorkflowTableHeaderCells = {
  WORKFLOW: {
    id: "workflow",
    label: "opva.workflow",
    width: 20,
    sorting: true,
    sortingProperty: "name",
    position: "left",
  } as TableHeadCell,
  DESCRIPTION: {
    id: "description",
    label: "opva.description",
    width: 15,
    sorting: true,
    sortingProperty: "description",
    position: "left",
  } as TableHeadCell,
  STATUS: {
    id: "status",
    width: 10,
    label: "opva.status",
    sorting: false,
    sortingProperty: "status",
    position: "left",
  } as TableHeadCell,
  VERSIONS: {
    id: "versions",
    width: 10,
    label: "opva.versions",
    sorting: true,
    sortingProperty: "noOfVersions",
    position: "center",
  } as TableHeadCell,
  CREATED_DATE: {
    id: "createdDate",
    label: "opva.createdDate",
    width: 15,
    sorting: true,
    sortingProperty: "createdAt",
    position: "left",
  } as TableHeadCell,
  UPDATED_DATE: {
    id: "updatedDate",
    label: "opva.updatedDate",
    width: 15,
    sorting: true,
    sortingProperty: "modifiedAt",
    position: "left",
  } as TableHeadCell,
  GROUPS_ASSIGNED: {
    id: "groupsAssigned",
    width: 10,
    label: "opva.groupsAssigned",
    sorting: true,
    sortingProperty: "groupsAssigned",
    position: "center",
  } as TableHeadCell,
  ASSIGN: {
    id: "assign",
    width: 10,
    label: "opva.assign",
    sorting: false,
    sortingProperty: "assign",
    position: "center",
  } as TableHeadCell,
  SHARE: {
    id: "share",
    width: 10,
    label: "opva.share",
    sorting: false,
    sortingProperty: "share",
    position: "center",
  } as TableHeadCell,
  TRACK_PROGRESS: {
    id: "trackProgress",
    width: 10,
    label: "opva.trackProgress",
    sorting: false,
    sortingProperty: "trackProgress",
    position: "center",
  } as TableHeadCell,
  DELETE: {
    id: "delete",
    width: 10,
    label: "opva.delete",
    sorting: false,
    sortingProperty: "delete",
    position: "center",
  } as TableHeadCell,
  PUBLISH: {
    id: "publish",
    width: 10,
    label: "opva.publish",
    sorting: false,
    sortingProperty: "publish",
    position: "center",
  } as TableHeadCell,
  COPY_CREATE: {
    id: "copyCreate",
    width: 10,
    label: "opva.copyCreate",
    sorting: false,
    sortingProperty: "copyCreate",
    position: "center",
  } as TableHeadCell,
  SUBMIT: {
    id: "submit",
    width: 10,
    label: "opva.submitResume",
    sorting: false,
    sortingProperty: "submit",
    position: "center",
  } as TableHeadCell,
  START: {
    id: "start",
    width: 10,
    label: "opva.startResume",
    sorting: false,
    sortingProperty: "start",
    position: "center",
  } as TableHeadCell,
  RESUME: {
    id: "resume",
    width: 10,
    label: "opva.resume",
    sorting: false,
    sortingProperty: "resume",
    position: "center",
  } as TableHeadCell,
  TURN_ON_OFF: {
    id: "turnOnOff",
    width: 10,
    label: "opva.turnOnOff",
    sorting: false,
    sortingProperty: "turnOnOff",
    position: "center",
  } as TableHeadCell,
  FAVORITE: {
    id: "favorite",
    width: 10,
    label: "opva.favorite",
    sorting: false,
    sortingProperty: "favorite",
    position: "center",
  } as TableHeadCell,
  DOWNLOAD: {
    id: "download",
    width: 10,
    label: "opva.download",
    sorting: false,
    sortingProperty: "download",
    position: "center",
  } as TableHeadCell,
};
