import {
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  InputAdornment,
  FormControl,
  Typography,
  Button,
  Dialog,
  DialogActions,
  Link,
  IconButton,
  TablePagination,
  Input,
  TextField,
  DialogContent,
  TableSortLabel,
  Box,
} from "@mui/material";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import "./Skills.scss";
import { visuallyHidden } from "@mui/utils";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { useEffect, useState, KeyboardEvent, useMemo } from "react";
import { SkillsData } from "../../models/SkillsData";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { NavLink } from "react-router-dom";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import { SkillsConstants } from "../../models/SkillsConstants";
import { useSkillsUtils } from "./SkillsUtil";
import { useTranslation } from "react-i18next";

export const Skills = () => {
  const { t } = useTranslation();
  const [skillData, setSkillsData] = useState<SkillsData[]>([]);
  const [displaySkillsData, setDisplaySkillsData] = useState<SkillsData[]>([]);
  const [editSkillData, setEditSkillData] = useState<SkillsData>();
  const { httpGet, httpPost, httpPut, handleAlertBar, httpDelete, checkRole } =
    useAPI();
  const [openDialog, setOpenDialog] = useState(false);
  const [skillObj, setSkillObj] = useState({
    skillName: "",
    skillDescription: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [mode, setMode] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [skillDataVisible, setskillDataVisible] = useState<SkillsData[]>([]);
  const [skillId, setSkillId] = useState<number>(0);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const { getSkillzHeaderCell } = useSkillsUtils();
  const [skillzHeaderCell, setSkillzHeaderCell] = useState<TableHeadCell[]>([]);

  const handleAlertDialogOpen = (groupId: number) => {
    setSkillId(groupId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setSkillId(0);
    setIsAlertDialog(false);
  };

  const handleAddSkills = async () => {
    setSkillObj({ skillName: "", skillDescription: "" });
    setOpenDialog(true);
    setMode("add");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditSkillsClick = (data: SkillsData) => {
    setOpenDialog(true);
    setSkillObj({ skillName: data.name, skillDescription: data.description });
    setMode("edit");
    setEditSkillData(data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const startIndex = newPage * rowsPerPage;
    setDisplaySkillsData([
      ...skillData.slice(startIndex, startIndex + rowsPerPage),
    ]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setDisplaySkillsData(skillData.slice(0, parseInt(event.target.value, 10)));
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      fetchSkillsData();
    }
  };
  const handleSorting = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEmptyPage = (data: any) => {
    const sliceArr = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    if (!sliceArr.length) {
      setPage(page === 0 ? 0 : page - 1);
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
  };

  const fetchSkillsData = async () => {
    setIsLoading(true);
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS, queryParams).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoading(false);
          } else {
            setSkillsData(response.data);
            setDisplaySkillsData(response.data.slice(0, rowsPerPage));
            setIsLoading(false);
            handleEmptyPage(response.data);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const extractDate = (dateString: string) => {
    const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const createSkillsData = async () => {
    const requestBody = {
      name: skillObj.skillName,
      description: skillObj.skillDescription,
    };
    await httpPost(OPTIVAL_CONSTANTS.API_URLS.CREATE_SKILLS, requestBody).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleCloseDialog();
            handleAlertBar("success", t("opva.skillCreateMsg"));
            fetchSkillsData();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleCreateUpdateSkills = () => {
    if (mode === "add") {
      createSkillsData();
    } else if (mode === "edit") {
      updateSkillsData();
    }
  };

  const updateSkillsData = async () => {
    const urlParams = {
      skillId: editSkillData?.id,
    };
    const requestBody = {
      name: skillObj.skillName,
      description: skillObj.skillDescription,
    };
    await httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_SKILLS, urlParams),
      requestBody
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleCloseDialog();
            handleAlertBar("success", t("opva.skillUpdateMsg"));
            fetchSkillsData();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleDeleteSkills = () => {
    const urlParams = {
      skillId: skillId,
    };
    httpDelete(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.DELETE_SKILLS, urlParams)
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        } else {
          handleAlertBar("success", t("opva.skillDeleteMsg"));
          fetchSkillsData();
          handleAlertDialogClose();
        }
      },
      (error) => {}
    );
  };
  const toggleDescriptionText = (skillData: SkillsData) => {
    skillData.isDescriptionViewMore = !skillData.isDescriptionViewMore;
    setskillDataVisible([...skillDataVisible]);
  };

  const renderTruncateDescription = (skillData: SkillsData) => {
    return !skillData.isDescriptionViewMore ? (
      <>
        {skillData.description.substring(
          0,
          SkillsConstants.SKILL_DESCRIPTION_LENGTH
        )}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(skillData)}
        >
          {t("opva.viewMore")}
        </Link>
      </>
    ) : (
      <>
        {skillData.description}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(skillData)}
        >
          {t("opva.viewLess")}
        </Link>
      </>
    );
  };

  useMemo(() => {
    const propertyType = orderBy === "usersAssigned" ? "number" : "string";
    const sortedList = tableSorting(skillData, order, orderBy, propertyType);
    setSkillsData(sortedList);
    setDisplaySkillsData(
      sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [order, orderBy, page, skillData]);

  useEffect(() => {
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        fetchSkillsData();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        fetchSkillsData();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  useEffect(() => {
    setSkillzHeaderCell(getSkillzHeaderCell());
  }, [skillData]);

  return (
    <div className="skillz-container">
      <div className="bg-light-blue px-4 pt-2 mb-4">
        <Breadcrumb pathList={[{ name: t("opva.skillz"), path: "skill" }]} />
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fw-bold mb-3">{t("opva.skill")}</h3>
          <div>
            <FormControl sx={{ marginRight: 2 }} variant="outlined">
              <Input
                id="search"
                type="text"
                placeholder={t("opva.searchSkill")}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <Link component="button" title={t("opva.clear")}>
                        <CloseIcon
                          fontSize="small"
                          onClick={() => setSearchText("")}
                        />
                      </Link>
                    </InputAdornment>
                  )
                }
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearchKeyEnter}
              />
            </FormControl>
            {/* <TuneIcon color="primary" style={{ marginRight: "10px" }} /> */}
            {checkRole([
              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
              OPTIVAL_CONSTANTS.SUPER_ADMIN,
              OPTIVAL_CONSTANTS.SUPERVISOR,
              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
            ]) && (
              <Button
                variant="contained"
                onClick={handleAddSkills}
                className="mb-2"
              >
                {t("opva.addSkill")}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }} className="px-5">
        <TableContainer className="table-container">
          <Table stickyHeader size="medium" aria-label="Skill table">
            <TableHead>
              <TableRow key="skill-table-header">
                {skillzHeaderCell.map((headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      orderBy === headCell.sortingProperty ? order : false
                    }
                    align={headCell.position}
                    sx={{
                      width: headCell.width + "%",
                    }}
                  >
                    {headCell.sorting ? (
                      <TableSortLabel
                        active={orderBy === headCell.sortingProperty}
                        direction={
                          orderBy === headCell.sortingProperty ? order : "asc"
                        }
                        onClick={() => handleSorting(headCell.sortingProperty)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableLoading column={skillzHeaderCell.length} />
            ) : (
              <TableBody>
                {displaySkillsData && displaySkillsData.length ? (
                  displaySkillsData.map((data: SkillsData) => (
                    <TableRow key={data.id}>
                      <TableCell
                        align="left"
                        style={{ wordBreak: "break-word" }}
                      >
                        {data.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ wordBreak: "break-word" }}
                      >
                        {data.description.length >
                        SkillsConstants.SKILL_DESCRIPTION_LENGTH
                          ? renderTruncateDescription(data)
                          : data.description}
                      </TableCell>
                      <TableCell align="left">{data.createdBy}</TableCell>
                      {/* <TableCell className="p-2" align="left">
                        {extractDate(data.createdAt)}
                      </TableCell> */}
                      <TableCell align="center">{data.usersAssigned}</TableCell>
                      {checkRole([
                        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                        OPTIVAL_CONSTANTS.SUPER_ADMIN,
                        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                        OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                      ]) && (
                        <TableCell align="center">
                          <NavLink
                            to={"/map-workflow"}
                            state={{ skillId: data.id, skillName: data.name }}
                          >
                            <LowPriorityIcon color="primary" />
                          </NavLink>
                        </TableCell>
                      )}
                      {checkRole([
                        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                        OPTIVAL_CONSTANTS.SUPER_ADMIN,
                        OPTIVAL_CONSTANTS.SUPERVISOR,
                      ]) && (
                        <TableCell align="center">
                          <NavLink
                            to={"/assign-skill"}
                            state={{ skillId: data.id, skillName: data.name }}
                          >
                            <AssignmentReturnOutlinedIcon color="primary" />
                          </NavLink>
                        </TableCell>
                      )}
                      {checkRole([
                        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                        OPTIVAL_CONSTANTS.SUPER_ADMIN,
                        OPTIVAL_CONSTANTS.SUPERVISOR,
                        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                      ]) && (
                        <>
                          <TableCell align="center">
                            <EditOutlinedIcon
                              sx={{ cursor: "pointer" }}
                              color="primary"
                              onClick={() => {
                                handleEditSkillsClick(data);
                              }}
                            />
                          </TableCell>
                          {/* <TableCell align="center">
                            <DeleteOutlineIcon
                             sx={{ cursor: "pointer" }}
                              color="disabled"
                              // onClick={() => handleAlertDialogOpen(data.id)}
                            />
                          </TableCell> */}
                        </>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      {searchText
                        ? t("opva.noSearchSkillDataAvailable")
                        : t("opva.noDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <div className="d-flex align-items-center justify-content-end">
            <TablePagination
              className="pagination-container mb-4"
              component="div"
              count={skillData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>
              {mode === "add" ? t("opva.create") : t("opva.edit")}
              {t("opva.skill")}
            </h5>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <TextField
            margin="dense"
            id="name"
            label={t("opva.name")}
            type="text"
            variant="standard"
            value={skillObj.skillName}
            required={skillObj.skillName.length === 0}
            fullWidth
            onChange={(e) =>
              setSkillObj({
                skillName: e.target.value,
                skillDescription: skillObj.skillDescription,
              })
            }
            error={
              skillObj.skillName.length > SkillsConstants.SKILLS_NAME_LENGTH
            }
            helperText={
              skillObj.skillName.length > SkillsConstants.SKILLS_NAME_LENGTH &&
              t("opva.skillNameLength")
            }
          />
          <TextField
            margin="dense"
            id="description"
            label={t("opva.description")}
            type="text"
            variant="standard"
            value={skillObj.skillDescription}
            required={skillObj.skillDescription.length === 0}
            fullWidth
            multiline
            rows={3}
            onChange={(e) =>
              setSkillObj({
                skillName: skillObj.skillName,
                skillDescription: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            onClick={handleCreateUpdateSkills}
            color="primary"
            variant="contained"
            disabled={
              skillObj.skillName.length === 0 ||
              skillObj.skillDescription.length === 0 ||
              skillObj.skillName.length > SkillsConstants.SKILLS_NAME_LENGTH
            }
          >
            {mode === "add" ? t("opva.createSkill") : t("opva.saveChanges")}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={isAlertDialog}
        title={t("opva.deleteSkil")}
        description={t("opva.wantToDeleteSkill")}
        submitBtnText={t("opva.delete")}
        handleSubmit={handleDeleteSkills}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
    </div>
  );
};
