import {
  Autocomplete,
  Checkbox,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { OPTIVAL_CONSTANTS } from "../../Constants";
import { useAPI } from "../../services/api/API";
import { useTranslation } from "react-i18next";

interface State {
  name: string;
}

interface Country {
  name: string;
}

interface FormData {
  organizationName: string;
  addressLine: string;
  addressLine2?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  email: string;
  mobileNumber: string;
  industrySector: string;
  opva?: string;
  orgPreferredLanguages: [
    {
      actualDisplayName: string;
      aws_language_code: string;
      display_name: string;
      gcp_language_code: string;
      id: 1;
      language_name: string;
    }
  ];
  errors: {
    organizationName: boolean;
    addressLine: boolean;
    addressLine2?: boolean;
    state?: boolean;
    country?: boolean;
    zipCode?: boolean;
    email: boolean;
    mobileNumber: boolean;
    industrySector: boolean;
    orgPreferredLanguages: boolean;
    opva?: boolean;
  };
}

interface OrganizationFormProps {
  formData: FormData;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  callFrom: string;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({
  formData,
  handleInputChange,
  handleSubmit,
  callFrom,
}) => {
  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 6);
    handleInputChange({ target: { name: "zipCode", value } });
  };
  const { httpGet, httpPost } = useAPI();
  const [languageList, setLanguageList] = useState<any>([]);
  const [languagePrefferedList, setLanguagePrefferedList] = useState<any>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const { t } = useTranslation();

  const countryProps = {
    options: countries,
    getOptionLabel: (option: Country) => option.name,
  };

  const statesProps = {
    options: states,
    getOptionLabel: (option: State) => option.name,
  };

  const fetchCountriesAndStates = async () => {
    try {
      const response = await httpGet(OPTIVAL_CONSTANTS.API_URLS.COUNTRY_DATA);
      if (response && response.data) {
        setCountries(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStateData = async (selectedCountry: string) => {
    try {
      const response = await httpPost(OPTIVAL_CONSTANTS.API_URLS.STATE_DATA, {
        country: selectedCountry,
      });

      if (response && response.data && response.data.data) {
        setStates(response.data.data.states || []);
        console.log(response.data.data.states);
      } else {
        console.error(
          "Error fetching states:",
          response?.data?.msg || "No response data"
        );
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleCountryChange = (
    event: any,
    value: Country | null,
    reason: string,
    details?: any
  ) => {
    if (value) {
      const selectedCountry = value.name;
      handleInputChange({
        target: { name: "country", value: selectedCountry },
      });
      handleInputChange({
        target: { name: "state", value: "" },
      });
      setStates([]);
      fetchStateData(selectedCountry);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    const updatedValue = newValue.some(
      (e: any) => e.language_name === "ENGLISH"
    )
      ? newValue
      : [languageList[0], ...newValue];
    setLanguagePrefferedList(updatedValue);
    handleInputChange({
      target: { name: "orgPreferredLanguages", value: updatedValue },
    });
  };
  const fetchLanguageList = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_LANGUAGE_LIST).then(
      (response) => {
        if (response) {
          console.log("fetchLanguageList->", response);
          setLanguageList(response?.data);
          const selectedLanguage = response?.data[0];
          setLanguagePrefferedList([selectedLanguage]);
        }
      },
      (error) => {}
    );
  };

  const handleKeyDown = (e: any) => {
    const isValidInput =
      !isNaN(e.key) || e.key === "Backspace" || e.key === "Delete";
    const isMaxLengthReached = e.target.value.length >= 10;
    if (
      (!isValidInput && e.key !== "Backspace" && e.key !== "Delete") ||
      (isMaxLengthReached && e.key !== "Backspace" && e.key !== "Delete")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    fetchCountriesAndStates();
  }, []);

  useEffect(() => {
    if (formData.country) {
      fetchStateData(formData.country);
    }
  }, [formData.country]);

  useEffect(() => {
    if (callFrom === "onBoardOrganisation") {
      fetchLanguageList();
    }
  }, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={t("opva.organizationName")}
            name="organizationName"
            value={formData.organizationName}
            onChange={handleInputChange}
            error={formData.errors.organizationName}
            helperText={
              formData.errors.organizationName &&
             t( "opva.enterOrganizationName")
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={t("opva.addressLine1")}
            value={formData.addressLine}
            name="addressLine"
            onChange={handleInputChange}
            error={formData.errors.addressLine}
            helperText={
              formData.errors.addressLine && t("opva.enterAddressLine")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            label={t("opva.addressLine2")}
            value={formData.addressLine2}
            name="addressLine2"
            onChange={handleInputChange}
            error={formData.errors.addressLine2 && formData.addressLine2 !== ""}
            helperText={
              formData.errors.addressLine2 && formData.addressLine2 !== ""
                ? t("opva.enterAddressLine2")
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3.5}>
          <Autocomplete
            {...countryProps}
            id="auto-select"
            autoSelect
            value={
              countries.find((country) => country.name === formData.country) ||
              null
            }
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField {...params} label={t("opva.country")} variant="standard" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3.5}>
          <Autocomplete
            {...statesProps}
            id="auto-select"
            value={
              states.find((state) => state.name === formData.state) || null
            }
            onChange={(event, value) =>
              handleInputChange({
                target: { name: "state", value: value ? value.name : "" },
              })
            }
            autoSelect
            renderInput={(params) => (
              <TextField {...params} label={t("opva.state")} variant="standard" />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4.7}>
          <TextField
            variant="standard"
            fullWidth
            label={t("opva.zipCode")}
            name="zipCode"
            value={formData.zipCode}
            onChange={handleZipCodeChange}
            error={!!(formData.zipCode && formData.zipCode.length !== 6)}
            helperText={
              formData.zipCode && formData.zipCode.length !== 6
                ? t("opva.enterValidZipCode")
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={t("opva.email")}
            value={formData.email}
            name="email"
            onChange={handleInputChange}
            error={formData.errors.email}
            helperText={formData.errors.email && t("opva.emailHelperText")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={t("opva.mobileNumber")}
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            error={formData.errors.mobileNumber}
            helperText={
              formData.errors.mobileNumber && t("opva.mobileNumberHelperText")
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={t("opva.industrySector")}
            value={formData.industrySector}
            name="industrySector"
            onChange={handleInputChange}
            error={formData.errors.industrySector}
            helperText={
              formData.errors.industrySector &&
            t("opva.industrySectorHelperText")
            }
          />
        </Grid>
        {callFrom === "onBoardOrganisation" ? (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              fullWidth
              limitTags={2}
              value={
                formData?.orgPreferredLanguages?.length > 0
                  ? formData?.orgPreferredLanguages
                  : languagePrefferedList
              }
              onChange={handleChange}
              options={languageList}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) =>
                option?.display_name === value?.display_name
              }
              getOptionLabel={(option: any) => option?.display_name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={option.id === 1}
                  />
                  {option.display_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={t("opva.selectLanguage")}
                  label={t("opva.preferredLanguage")}
                />
              )}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            label={t("opva.useOpvaSolutionFor")}
            value={formData.opva}
            name="opva"
            onChange={handleInputChange}
            error={formData.errors.opva && formData.opva !== ""}
            helperText={
              formData.errors.opva && formData.opva !== ""
                ? t("opva.enterOpvaHelperText")
                : ""
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default OrganizationForm;
