import "./UserGroupList.scss";
import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { visuallyHidden } from "@mui/utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { cloneDeep, differenceBy, isEmpty } from "lodash";
import {
  TableDataActionsModel,
  UploadBulkUserModel,
  UserAssignedRolesListModel,
  UserListModel,
  UserListProps,
} from "../../models/GroupManagementModels";
import { GroupManagementConstants, useGroupManagementUtils } from "../../constants/GroupManagementConstants";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { useAPI } from "../../../../shared/services/api/API";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { AppDispatch, RootState } from "../../../../shared/Store";
import {
  setAddUserList,
  setRemoveUserList,
} from "../../../../shared/reducers/UserSelectedReducer";
import { DownloadExcelButton } from "../../../../shared/components/Download/DownloadExcelButton";
import { useTranslation } from "react-i18next";

let roleList = [
  OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
  OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
  OPTIVAL_CONSTANTS.CONTENT_ADMIN,
  OPTIVAL_CONSTANTS.CONTENT_EDITOR,
  OPTIVAL_CONSTANTS.SUPERVISOR,
  OPTIVAL_CONSTANTS.EMPLOYEE,
];

export const UserGroupList = (props: UserListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const routeParams = new URLSearchParams(location.search);
  const { httpGet, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const dispatch: AppDispatch = useDispatch();
  const userSelectState = useSelector((state: RootState) => state.userSelect);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "firstName",
    searchText: "",
  } as TableDataActionsModel);
  const [addedUserByGroupList, setAddedUserByGroupList] = useState<
    UserListModel[]
  >([]);
  const [userByGroupList, setUserByGroupList] = useState<UserListModel[]>([]);
  const [filteredUserGroupList, setFilteredUserGroupList] = useState<
    UserListModel[]
  >([]);
  const [uploadBulkUser, setUploadBulkUser] = useState({
    fileName: null,
    fileFormatError: false,
  } as UploadBulkUserModel);
  const [errorMsg, setErrorMsg] = useState("");
  const { getUsersTableHeaders } = useGroupManagementUtils();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleRemoveUserData = (userData: UserListModel, index: number) => {
    userByGroupList.splice(index, 1);
    setUserByGroupList([...userByGroupList]);
    dispatch(setRemoveUserList([userData]));
    handleUserListChange("REMOVE");
  };

  const handleUserListChange = (action?: string) => {
    if (props?.isEditMode && !isEmpty(addedUserByGroupList)) {
      if (action === "ADD" && props?.sendAddedUserList) {
        props?.sendAddedUserList(
          differenceBy(userByGroupList, addedUserByGroupList, "id")
        );
      }
      if (action === "REMOVE" && props?.sendRemovedUserList) {
        props?.sendRemovedUserList(
          differenceBy(addedUserByGroupList, userByGroupList, "id")
        );
      }
    } else {
      if (props?.sendAddedUserList) props?.sendAddedUserList(userByGroupList);
    }
  };

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setUploadBulkUser((prevState) => ({
        ...prevState,
        fileName: dropFiles,
      }));
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setUploadBulkUser({
        fileName: null,
        fileFormatError: false,
      });
    }
  };

  const handleSelectFileChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setUploadBulkUser((prevState) => ({
        ...prevState,
        fileName: file,
      }));
      if (props?.sendBulkUserFile) props.sendBulkUserFile(file);
    }
  };

  const handleRemoveSelectedFile = () => {
    setUploadBulkUser({
      fileName: null,
      fileFormatError: false,
    });
    if (props?.sendBulkUserFile) props.sendBulkUserFile(null);
  };

  const fetchUserGroups = () => {
    const urlParams = {
      groupId:
        routeParams && routeParams.get("groupId")
          ? routeParams.get("groupId")
          : 0,
    };
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "firstName",
      isDescending: true,
    };
    httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_EMPLOYEES_BY_GROUP,
        urlParams
      ),
      queryParams
    ).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          setUserByGroupList(response.data);
          setAddedUserByGroupList(cloneDeep(response.data));
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const getUserRoles = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_ROLES, undefined, false).then(
      (response) => {
        if (response && response.data) {
          roleList = response.data
            .filter((e: any) => !e.applicationRole)
            .map((v: any) => v.name);
        }
      },
      (error) => {}
    );
  };

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "users" ? "number" : "string";
    const sortedList = tableSorting(
      userByGroupList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setUserByGroupList(sortedList);
    setFilteredUserGroupList(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, userByGroupList]);

  useEffect(() => {
    if (
      userSelectState &&
      userSelectState.addUserList &&
      userSelectState.addUserList.length > 0
    ) {
      userByGroupList.unshift(...userSelectState.addUserList);
      setUserByGroupList([...userByGroupList]);
      dispatch(setAddUserList([]));
      handleUserListChange("ADD");
    }
  }, [userSelectState]);

  // Call fetchData on component mount
  useEffect(() => {
    getUserRoles();
    if (props.isEditMode) {
      fetchUserGroups();
    }
  }, []);

  return (
    <div className="user-list-container">
      <Grid container>
        <Grid item xs sm md={8} lg={8}>
          <Typography variant="bodyMedium">{props.title}</Typography>
          <TableContainer className="user-list-table-container mt-2">
            <Table
              stickyHeader
              sx={{ width: "100%" }}
              size="medium"
              aria-label="User List Table"
            >
              <TableHead>
                <TableRow key="group-mgmt-table-header">
                  {getUsersTableHeaders().map(
                    (headCell: TableHeadCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          tableDataActions.orderBy === headCell.sortingProperty
                            ? tableDataActions.order
                            : false
                        }
                        align={headCell.position}
                        sx={{
                          width: headCell.width + "%",
                        }}
                      >
                        {headCell.sorting ? (
                          <TableSortLabel
                            active={
                              tableDataActions.orderBy ===
                              headCell.sortingProperty
                            }
                            direction={
                              tableDataActions.orderBy ===
                              headCell.sortingProperty
                                ? tableDataActions.order
                                : "asc"
                            }
                            onClick={(event) =>
                              handleSorting(headCell.sortingProperty)
                            }
                          >
                            {headCell.label}
                            {tableDataActions.orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {tableDataActions.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {isSpinnerShow ? (
                <TableLoading
                  column={getUsersTableHeaders().length}
                />
              ) : (
                <TableBody>
                  {filteredUserGroupList && filteredUserGroupList.length > 0 ? (
                    filteredUserGroupList.map(
                      (data: UserListModel, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            {data.firstName} {data.lastName}
                          </TableCell>
                          <TableCell>{data?.employeeId}</TableCell>
                          <TableCell>{data.mail}</TableCell>
                          <TableCell>
                            {data?.assignedRoles &&
                            data?.assignedRoles.length > 0
                              ? data.assignedRoles.map(
                                  (
                                    roleData: UserAssignedRolesListModel,
                                    index: number,
                                    roleList: UserAssignedRolesListModel[]
                                  ) => roleData.name + ","
                                )
                              : ""}
                          </TableCell>
                          <TableCell>{data?.position}</TableCell>
                          <TableCell align="center">
                            <Link
                              component="button"
                              title={t("opva.removeUser")}
                              onClick={(e) => handleRemoveUserData(data, index)}
                            >
                              <DeleteForeverIcon color="error" />
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={
                          getUsersTableHeaders().length
                        }
                      >
                        {t("opva.noUserAddedHere")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div className="d-flex align-items-center justify-content-end">
            <TablePagination
              component="div"
              className="pagination-container"
              count={userByGroupList.length}
              page={tableDataActions.page}
              rowsPerPage={tableDataActions.perPageRows}
              onPageChange={handlePaginationChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Grid>
        <Grid item xs sm md={4} lg={4} className="ps-4">
          <div className="mb-3">
            <Typography variant="bodyMedium">{t("opva.addBulkUsers")}</Typography>
          </div>
          <div className="mb-3">
            <Typography variant="bodyMedium">
              {t("opva.downloadFormatForBulkUsersGroup")}
            </Typography>
          </div>
          <div
            className="upload-box-container"
            onDragOver={handleUploadBoxDragOver}
            onDrop={handleUploadBoxDrop}
          >
            <input
              type="file"
              accept=".xlsx"
              hidden
              id="user-file-upload"
              onChange={handleSelectFileChange}
            />
            <div className="d-flex align-self-center mb-2">
              {!uploadBulkUser.fileName && (
                <label htmlFor="user-file-upload" className="file-upload-icon">
                  <FileUploadOutlinedIcon color="primary" />
                </label>
              )}
              {uploadBulkUser.fileName && (
                <Chip
                  color="primary"
                  sx={{ minWidth: "auto", maxWidth: "250px" }}
                  label={uploadBulkUser.fileName?.name}
                  title={uploadBulkUser.fileName?.name}
                  onDelete={handleRemoveSelectedFile}
                />
              )}
            </div>
            <div className="d-flex align-self-center mb-2">
              <label htmlFor="user-file-upload">
                <Link variant="bodyNormal" underline="none" className="me-1">
                  <b>{t("opva.clickHere")}</b>
                </Link>
                <Typography variant="bodyNormal">
                {t("opva.uploadOrDropExcelFile")}
                </Typography>
              </label>
            </div>
          </div>
          <div style={{ marginLeft: "25px" }}>
            {errorMsg && (
              <Typography variant="body1" color="error">
                {`Error: ${errorMsg}`}
              </Typography>
            )}
          </div>
          <div className="mb-3">
            <Typography variant="bodyMedium">
            {t("opva.downloadFormatHere")}
            </Typography>
          </div>
          <DownloadExcelButton
            header={GroupManagementConstants.BULK_USER_HEADER_LIST}
            options={roleList}
            optionCell={"F"}
            listLength={50}
          />
        </Grid>
      </Grid>
    </div>
  );
};
