import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
} from "@mui/material";
import "./AssignSkills.scss";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, KeyboardEvent, useMemo } from "react";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { SkillsToEmployee, SortObj } from "./models/SkillsToEmployee";
import { visuallyHidden } from "@mui/utils";
import { TableHeadCell } from "../../../../shared/models/TableModels";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { SkillsConstants, useMapAndAssignUtils } from "../../models/SkillsConstants";
import { DownloadExcelButton } from "../../../../shared/components/Download/DownloadExcelButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTranslation } from "react-i18next";

export const AssignSkills = () => {
  const navigate = useNavigate();
  const { httpGet, httpPost, handleAlertBar, httpDelete } = useAPI();
  const [searchText, setSearchText] = useState("");
  const [isLoadingUnAssign, setIsLoadingUnAssign] = useState(true);
  const [unAssignpage, setUnAssignPage] = useState(0);
  const [unAssignRowsPerPage, setUnAssignRowsPerPage] = useState(5);
  const [unAssignedUsers, setUnAssignedUsers] = useState<SkillsToEmployee[]>(
    []
  );
  const [displayUnAssignedUsers, setDisplayUnAssignedUsers] = useState<
    SkillsToEmployee[]
  >([]);
  const [assignedUsers, setAssignedUsers] = useState<SkillsToEmployee[]>([]);
  const [isLoadingAssign, setIsLoadingAssign] = useState(true);
  const [assignPage, setAssignPage] = useState(0);
  const [assignRowsPerPage, setAssignRowsPerPage] = useState(5);
  const [displayAssignedUsers, setDisplayAssignedUsers] = useState<
    SkillsToEmployee[]
  >([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [unAssignSort, setUnAssignSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const [assignSort, setAssignSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const [skillsLevel, setSkillsLevel] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [assignData, setAssignData] = useState<SkillsToEmployee>();
  const { state } = useLocation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const excelHeader = [
    "Skill Name*",
    "Skill Level*",
    "Email address*(Emails seperated with comma)",
  ];
  const excelListOptions = ["Beginner", "Intermediate", "Advanced", "Expert"];
  const defaultValues = {
    A: state.skillName,
    B: "Beginner",
  };

  const { t } = useTranslation();
  const { getAssignWorkflowHeaders } = useMapAndAssignUtils();

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setSelectedFile(dropFiles);
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setSelectedFile(null);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
    event.target.value = "";
  };

  const handleRemoveClick = () => {
    setSelectedFile(null);
  };

  const handleBulkUser = async () => {
    const urlParams = {
      skillId: state.skillId,
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    await httpPost(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.ASSIGN_BULK_SKILL_EMPLOYEES,
        urlParams
      ),
      formData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              t(`opva.${response?.data?.code}`) || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar("success", t("opva.skillsAssignedSuccessfullyMsg"));
            setSelectedFile(null);
            getAssingedSkillsToEmployee();
            getUnAssignedSkillsToEmployee();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const backToSkillz = () => {
    navigate("/skill");
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      getUnAssignedSkillsToEmployee();
      getAssingedSkillsToEmployee();
    }
  };

  const handleChangePageForUnassigned = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setUnAssignPage(newPage);
    const startIndex = newPage * unAssignRowsPerPage;
    setDisplayUnAssignedUsers([
      ...unAssignedUsers.slice(startIndex, startIndex + unAssignRowsPerPage),
    ]);
  };

  const handleChangeRowsPerPageForUnassigned = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUnAssignRowsPerPage(parseInt(event.target.value, 10));
    setUnAssignPage(0);
    setDisplayUnAssignedUsers(
      unAssignedUsers.slice(0, parseInt(event.target.value, 10))
    );
  };

  const handleChangePageForAssigned = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setAssignPage(newPage);
    const startIndex = newPage * assignRowsPerPage;
    setDisplayAssignedUsers([
      ...assignedUsers.slice(startIndex, startIndex + assignRowsPerPage),
    ]);
  };

  const handleChangeRowsPerPageForAssigned = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAssignRowsPerPage(parseInt(event.target.value, 10));
    setAssignPage(0);
    setDisplayAssignedUsers(
      assignedUsers.slice(0, parseInt(event.target.value, 10))
    );
  };

  const handleSorting = (property: string, table: string) => {
    if (table === "unAssignTable") {
      const isAsc =
        unAssignSort.orderBy === property && unAssignSort.order === "asc";
      setUnAssignSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    } else if (table === "assignTable") {
      const isAsc =
        assignSort.orderBy === property && assignSort.order === "asc";
      setAssignSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    }
  };

  const handleEmptyPage = (data: any, table: string) => {
    if (table === "assignedUser") {
      const sliceArr = data.slice(
        assignPage * assignRowsPerPage,
        assignPage * assignRowsPerPage + assignRowsPerPage
      );
      if (!sliceArr.length) {
        setAssignPage(assignPage === 0 ? 0 : assignPage - 1);
      }
    } else if (table === "unAssignedUser") {
      const sliceArr = data.slice(
        unAssignpage * unAssignRowsPerPage,
        unAssignpage * unAssignRowsPerPage + unAssignRowsPerPage
      );
      if (!sliceArr.length) {
        setUnAssignPage(unAssignpage === 0 ? 0 : unAssignpage - 1);
      }
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
  };

  const getUnAssignedSkillsToEmployee = async () => {
    setIsLoadingUnAssign(true);
    const urlParams = {
      skillId: state.skillId,
      //skillId: 23,
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_USERS_NOT_ASSIGNED_FOR_SKILL,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoadingUnAssign(false);
          } else {
            setUnAssignedUsers(response.data);
            setDisplayUnAssignedUsers(
              response.data.slice(0, unAssignRowsPerPage)
            );

            setIsLoadingUnAssign(false);
            handleEmptyPage(response.data, "unAssignedUser");
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoadingUnAssign(false);
        }
      },
      (error: any) => {
        setIsLoadingUnAssign(false);
      }
    );
  };

  const getAssingedSkillsToEmployee = async () => {
    setIsLoadingAssign(true);
    const urlParams = {
      skillId: state.skillId,
      //skillId: 23,
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_USER_ASSIGNED_FOR_SKILL,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoadingAssign(false);
          } else {
            setAssignedUsers(response.data);
            setDisplayAssignedUsers(response.data.slice(0, assignRowsPerPage));
            setIsLoadingAssign(false);
            handleEmptyPage(response.data, "assignedUser");
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoadingAssign(false);
        }
      },
      (error: any) => {
        setIsLoadingAssign(false);
      }
    );
  };

  const assignSkillToEmployee = async (id: any) => {
    const requestBody = {
      employeeIds: [id],
      skillId: state.skillId,
      //skillId: 23,
      skillLevelId: selectedSkill.id,
    };
    await httpPost(
      OPTIVAL_CONSTANTS.API_URLS.ADD_UNASSIGNED_SKILLS_TO_EMPLOYEE,
      requestBody
    ).then(
      (response: any) => {
        if (response && response.status) {
          if (response.status === 200) {
            handleAlertBar("success", t("opva.skillAssignEmpSuccessfullyMsg"));
            getAssingedSkillsToEmployee();
            getUnAssignedSkillsToEmployee();
            setOpenDialog(false);
            setSelectedSkill(null);
            //setAssignData(undefined);
          } else {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {}
    );
  };

  const removeSkillFromEmployee = async (id: number) => {
    const requestBody = {
      employeeIds: [id],
      skillId: state.skillId,
      //skillId: 23,
    };
    httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.REMOVE_SKILL_FROM_EMPLOYEE,
      requestBody
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        } else {
          handleAlertBar(
            "success",
            t("opva.skillRemovedFromEmpSucceddfully")
          );
          getAssingedSkillsToEmployee();
          getUnAssignedSkillsToEmployee();
        }
      },
      (error) => {}
    );
  };

  const handleTabChange = (_event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const handleAddSkillToUser = (data: SkillsToEmployee) => {
    assignSkillToEmployee(data.id);
  };

  const handleRemoveSkillFromEmployee = (data: SkillsToEmployee) => {
    removeSkillFromEmployee(data.id);
  };
  const getSkillsLevels = async () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_LEVELS).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          setSkillsLevel(response.data);
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const handleSkillChange = (event: any) => {
    const skill = skillsLevel.find(
      (skill: any) => skill?.id === parseInt(event.target.value, 10)
    );
    setSelectedSkill(skill);
  };

  useMemo(() => {
    const propertyType =
      unAssignSort.orderBy === "employeeId" ? "number" : "string";
    const sortedList = tableSorting(
      unAssignedUsers,
      unAssignSort.order,
      unAssignSort.orderBy,
      propertyType
    );
    setUnAssignedUsers(sortedList);
    setDisplayUnAssignedUsers(
      sortedList.slice(
        unAssignpage * unAssignRowsPerPage,
        unAssignpage * unAssignRowsPerPage + unAssignRowsPerPage
      )
    );
  }, [unAssignSort, unAssignpage, unAssignedUsers]);

  useMemo(() => {
    const propertyType =
      assignSort.orderBy === "employeeId" ? "number" : "string";
    const sortedList = tableSorting(
      assignedUsers,
      assignSort.order,
      assignSort.orderBy,
      propertyType
    );
    setAssignedUsers(sortedList);
    setDisplayAssignedUsers(
      sortedList.slice(
        assignPage * assignRowsPerPage,
        assignPage * assignRowsPerPage + assignRowsPerPage
      )
    );
  }, [assignSort, assignPage, assignedUsers]);

  useEffect(() => {
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        getUnAssignedSkillsToEmployee();
        getAssingedSkillsToEmployee();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        getUnAssignedSkillsToEmployee();
        getAssingedSkillsToEmployee();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  return (
    <div className="assign-skillz-container">
      <div className="bg-light-blue pt-2 mb-3">
        <div className="px-4">
          <Breadcrumb
            pathList={[
              { name: t("opva.skillz"), path: "skill" },
              { name: t( "opva.assignSkill"), path: "assign-skill" },
            ]}
          />
          <Grid container alignItems="center" className="pt-2 pb-4">
            <Grid item xs md lg={6} className="d-flex align-items-center">
              <Link
                component="button"
                title={t("opva.backToSkill")}
                className="me-2"
                onClick={backToSkillz}
              >
                <KeyboardArrowLeftIcon fontSize="large" />
              </Link>
              <Typography variant="h4" className="mb-0">
                {t("opva.assign")}&nbsp;[{state.skillName}]
              </Typography>
            </Grid>
            <Grid
              item
              xs
              md
              lg={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Input
                id="search"
                type="text"
                placeholder={t("opva.search")}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <Link component="button" title={t("opva.clear")}>
                        <CloseIcon
                          fontSize="small"
                          onClick={() => setSearchText("")}
                        />
                      </Link>
                    </InputAdornment>
                  )
                }
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearchKeyEnter}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} className="bg-white px-4">
          <Grid item xs={12} md={8} p={3}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={t("opva.user")} />
              {/* <Tab label="Groups" /> */}
            </Tabs>

            <TableContainer>
              <Table
                stickyHeader
                size="medium"
                aria-label="UnAssignSkillz table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="checkbox"
                    ></TableCell>
                    {getAssignWorkflowHeaders().map(
                      (headCell: TableHeadCell) => (
                        <TableCell
                          key={headCell.id}
                          sortDirection={
                            unAssignSort.orderBy === headCell.id
                              ? unAssignSort.order
                              : false
                          }
                          align={headCell.position}
                          sx={{
                            width: headCell.width + "%",
                          }}
                        >
                          {headCell?.sorting ? (
                            <TableSortLabel
                              active={
                                unAssignSort.orderBy ===
                                headCell.sortingProperty
                              }
                              direction={
                                unAssignSort.orderBy ===
                                headCell.sortingProperty
                                  ? unAssignSort.order
                                  : "desc"
                              }
                              onClick={(event) =>
                                handleSorting(
                                  headCell.sortingProperty,
                                  "unAssignTable"
                                )
                              }
                            >
                              {headCell.label}
                              {unAssignSort.orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {unAssignSort.order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {isLoadingUnAssign ? (
                  <TableLoading
                    column={getAssignWorkflowHeaders().length + 1}
                  />
                ) : (
                  <TableBody>
                    {unAssignedUsers && unAssignedUsers.length ? (
                      displayUnAssignedUsers.map((data: SkillsToEmployee) => (
                        <TableRow key={data.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="checkbox"
                          >
                            <img
                              src="images/User-Thumb.svg"
                              alt="User Icon"
                              width={40}
                              height={40}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {data.firstName} {data.lastName}
                          </TableCell>
                          <TableCell>{data.employeeId}</TableCell>
                          <TableCell>{data.mail}</TableCell>
                          <TableCell>
                            {data.assignedRoles.length
                              ? data.assignedRoles
                                  .map((roles: any) => roles.name)
                                  .join(", ")
                              : ""}
                          </TableCell>

                          <TableCell>{data.status}</TableCell>
                          <TableCell align="center">
                            <AddIcon
                              color="primary"
                              onClick={() => {
                                setOpenDialog(true);
                                setAssignData(data);
                                getSkillsLevels();
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell align="center" colSpan={7}>
                          {searchText
                            ? t("opva.searchUnasssignDataNotAvailable")
                            : t("opva.noDataAvailable")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
              <div className="d-flex align-items-center justify-content-end">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={unAssignedUsers.length}
                  page={unAssignpage}
                  onPageChange={handleChangePageForUnassigned}
                  rowsPerPage={unAssignRowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPageForUnassigned}
                />
              </div>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={4} mt={1}>
            <Grid container item xs={12} className="mt-4 assign-bulk">
              <Grid item xs={12} className="mb-3 mt-4">
                <Typography variant="body1" className="mb-2 mt-2">
                  {t("opva.assignToBulkUsers")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
                  {t("opva.downloadAssignSkillFormat")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="upload-box-container"
                  onDragOver={handleUploadBoxDragOver}
                  onDrop={handleUploadBoxDrop}
                >
                  <input
                    type="file"
                    accept=".xlsx"
                    hidden
                    id="user-file-upload"
                    onChange={handleFileChange}
                  />
                  <div className="d-flex align-self-center mb-2">
                    {!selectedFile && (
                      <label
                        htmlFor="user-file-upload"
                        className="file-upload-icon"
                      >
                        <FileUploadOutlinedIcon color="primary" />
                      </label>
                    )}
                    {selectedFile && (
                      <Chip
                        color="primary"
                        sx={{ minWidth: "auto", maxWidth: "250px" }}
                        label={selectedFile.name}
                        title={selectedFile.name}
                        onDelete={handleRemoveClick}
                      />
                    )}
                  </div>
                  <div className="d-flex align-self-center mb-2">
                    <label htmlFor="user-file-upload">
                      <Link
                        variant="bodyNormal"
                        underline="none"
                        className="me-1"
                      >
                        <b>{t("opva.clickHere")}</b>
                      </Link>
                      <Typography variant="bodyNormal">
                        {t("opva.uploadOrDropExcelFile")}
                      </Typography>
                    </label>
                  </div>
                </div>
                <div style={{ marginLeft: "25px" }}>
                  {errorMsg && (
                    <Typography variant="body1" color="error">
                      {`Error: ${errorMsg}`}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} className="mb-4">
                {t("opva.downloadAssignSkillFormat")}
              </Grid>
              <Grid item xs={12} md={6} className="mb-4">
                <DownloadExcelButton
                  header={excelHeader}
                  options={excelListOptions}
                  optionCell={"B"}
                  listLength={3}
                  defaultValues={defaultValues}
                />
              </Grid>
              <Grid item xs={12} md={6} className="mb-4">
                <Button
                  size="large"
                  variant="contained"
                  sx={{ color: "#FFFFFFCC" }}
                  onClick={handleBulkUser}
                  disabled={!selectedFile}
                >
                  {t("opva.addBulkUsers")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box className="assignedusers-container bg-white px-4">
          <Typography variant="subtitle1" m={1}>
            {t("opva.assignedUser")}
          </Typography>

          <TableContainer>
            <Table
              stickyHeader
              sx={{ width: "100%" }}
              size="medium"
              aria-label="AssignSkillz table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="checkbox"
                  ></TableCell>
                  {getAssignWorkflowHeaders().map(
                    (headCell: TableHeadCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          assignSort.orderBy === headCell.id
                            ? assignSort.order
                            : false
                        }
                        align={headCell.position}
                        sx={{
                          width: headCell.width + "%",
                        }}
                      >
                        {headCell?.sorting ? (
                          <TableSortLabel
                            active={
                              assignSort.orderBy === headCell.sortingProperty
                            }
                            direction={
                              assignSort.orderBy === headCell.sortingProperty
                                ? assignSort.order
                                : "desc"
                            }
                            onClick={(event) =>
                              handleSorting(
                                headCell.sortingProperty,
                                "assignTable"
                              )
                            }
                          >
                            {headCell.label}
                            {assignSort.orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {assignSort.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                        {/* <Typography style={{ fontWeight: "500" }}>
                    {column.label}
                  </Typography> */}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {isLoadingAssign ? (
                <TableLoading
                  column={getAssignWorkflowHeaders().length + 1}
                />
              ) : (
                <TableBody>
                  {assignedUsers && assignedUsers.length ? (
                    displayAssignedUsers.map((data: SkillsToEmployee) => (
                      <TableRow key={data.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="checkbox"
                        >
                          <img
                            src="images/User-Thumb.svg"
                            alt="User Icon"
                            width={40}
                            height={40}
                          />
                        </TableCell>
                        <TableCell>
                          {data.firstName} {data.lastName}
                        </TableCell>

                        <TableCell>{data.employeeId}</TableCell>
                        <TableCell>{data.mail}</TableCell>
                        <TableCell>
                          {data.assignedRoles.length
                            ? data.assignedRoles
                                .map((roles: any) => roles.name)
                                .join(", ")
                            : ""}
                        </TableCell>

                        <TableCell>{data.status}</TableCell>
                        <TableCell align="center">
                          <DeleteOutlineIcon
                            color="error"
                            onClick={() => handleRemoveSkillFromEmployee(data)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        {searchText
                          ? t("opva.searchAsssignDataNotAvailable")
                          : t("opva.noDataAvailable")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            <div className="d-flex align-items-center justify-content-end">
              <TablePagination
                component="div"
                className="pagination-container mb-2"
                rowsPerPageOptions={[5, 10, 25]}
                count={assignedUsers.length}
                page={assignPage}
                onPageChange={handleChangePageForAssigned}
                rowsPerPage={assignRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPageForAssigned}
              />
            </div>
          </TableContainer>
        </Box>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("opva.assignToUser")}</Typography>
            <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="body1" className="m-2">
            {t("opva.selectedUser")}
          </Typography>
          <div>
            <Chip
              key={assignData?.id}
              label={`${assignData?.firstName} ${assignData?.lastName}`}
              color="primary"
              style={{ margin: "4px" }}
            />
          </div>

          <div>
            <Typography variant="body1" className="m-1">
              {t("opva.selectSkillProficiency")}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                className="ms-1"
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="skill"
                value={selectedSkill?.id}
                onChange={handleSkillChange}
              >
                {skillsLevel.map((skill: any) => (
                  <FormControlLabel
                    key={skill.id}
                    value={skill.id}
                    control={<Radio />}
                    label={t(`opva.${skill.name.toLowerCase()}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="primary"
            variant="contained"
            onClick={() => assignSkillToEmployee(assignData?.id)}
            disabled={!assignData || selectedSkill === null}
          >
            {t("opva.assignSkill")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
