import "./Menu.scss";
import { NavLink } from "react-router-dom";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { useAPI } from "../../../shared/services/api/API";
import { useTranslation } from "react-i18next";

export const Menu = () => {
  const { checkRole } = useAPI();
  const { t } = useTranslation();
  return (
    <div className="menu-container">
      {!checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && <NavLink to={"/home"}>{t("opva.home")}</NavLink>}
      {!checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && <NavLink to={"/workflow"}>{t("opva.instrukt")}</NavLink>}
      {!checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && <NavLink to={"/skill"}>{t("opva.skillz")}</NavLink>}
      {checkRole([
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
      ]) && <NavLink to={"/management"}>{t("opva.management")}</NavLink>}
      {checkRole([
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
        OPTIVAL_CONSTANTS.CONTENT_EDITOR,
        OPTIVAL_CONSTANTS.SUPERVISOR,
      ]) && (
        <NavLink to={"/organization-profile"}>
          {t("opva.organizationProfile")}
        </NavLink>
      )}
      {checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && (
        <NavLink to={"/onboard-organisation"}>
          {OPTIVAL_CONSTANTS.ONBOARD_ORGANIZATION_MENU}
        </NavLink>
      )}
      {checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && (
        <NavLink to={"/manage-organisation"}>
          {OPTIVAL_CONSTANTS.MANAGE_ORGANIZATION_MENU}
        </NavLink>
      )}
      {checkRole([
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SALES_TEAM,
        OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
      ]) && (
        <NavLink to={"/signup-request"}>
          {OPTIVAL_CONSTANTS.SIGNUP_REQUEST_MENU}
        </NavLink>
      )}
    </div>
  );
};
