import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadcrumbPath, BreadcrumbProps } from "../models/BreadcrumbModels";
import { useTranslation } from "react-i18next";

export const Breadcrumb = (props: BreadcrumbProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="my-3"
    >
      <Link underline="none" key="home" onClick={(e) => navigate("/home")}>
       {t( "opva.home")}
      </Link>
      {props.pathList &&
        props.pathList.length > 0 &&
        props.pathList.map(
          (
            data: BreadcrumbPath,
            index: number,
            sourceList: BreadcrumbPath[]
          ) => {
            return index + 1 === sourceList.length ? (
              <Typography key={data.name} color="text.primary">
                {data.name}
              </Typography>
            ) : (
              <Link
                underline="none"
                key={data.name}
                onClick={(e) => navigate("/" + data.path)}
              >
                {data.name}
              </Link>
            );
          }
        )}
    </Breadcrumbs>
  );
};
