import { useContext, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { DownloadExcelButton } from "../../../../shared/components/Download/DownloadExcelButton";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { TagManagementConstants } from "../constants/TagManagementConstants";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import "./NewTag.scss";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useTranslation } from "react-i18next";

export const NewTag = (Props: any) => {
  const { httpPost, httpPut, handleAlertBar } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tagId = queryParams.get("id");
  const { authUserDetails } = useContext(AuthenticationContext);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const { t } = useTranslation();

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setSelectedFile(dropFiles);
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setSelectedFile(null);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const previousButton = () => {
    navigate("/tags-management");
  };

  const handleRemoveClick = () => {
    setSelectedFile(null);
  };
  const header = ["ProductTag*", "Description*"];
  const defaultValues = { A: "Moback", B: "Hi" };

  const createTag = async (param: any) => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    await httpPost(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.TAG, urlParams),
      param
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("add tag response-->", response.data);
            handleAlertBar(
              "success",
              t("opva.addTagSuccessMsg")
            );
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        console.error("Error creating tag:", error);
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
    );
  };

  const updateTagData = async (param: any) => {
    const urlParams = {
      tagId: tagId,
    };
    await httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_TAG, urlParams),
      param
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("updated tag response-->", response.data);
            handleAlertBar(
              "success",
              t("opva.updatedTagSuccessMsg")
            );
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        console.error("Error updating tag:", error);
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const handleBulkUser = async () => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    await httpPost(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.ADD_BULK_TAGS, urlParams),
      formData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("add tag response-->", response.data);
            handleAlertBar(
              "success",
              t("opva.addTagSuccessMsg")
            );
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleCreateUpdateTag = (event: any) => {
    event.preventDefault();
    if (isButtonEnabled()) {
      const param = {
        name: formData.name,
        description: formData.description,
      };
      console.log("Form submitted:", formData);
      setFormData((prevData) => ({ ...prevData }));
      if (Props?.isEdit) {
        updateTagData(param);
      } else {
        createTag(param);
      }
    }
  };
  const isButtonEnabled = () => {
    return (
      formData.name.trim().length > 0 && formData.description.trim().length > 0
    );
  };
  useEffect(() => {
    if (location.state && location.state.tagData) {
      const { name, description } = location.state.tagData;
      setFormData({ name, description });
    } else {
      setFormData({ name: "", description: "" });
    }
  }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="tag-management px-5 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.tagManagement"), path: "new-tags" },
          ]}
        />

        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              component="button"
              title={t("opva.backToTagManagement")}
              className="me-2"
              onClick={previousButton}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>

            <Typography variant="h3" className="mb-0">
              {Props.isEdit
                ? t("opva.updateTag")
                : t("opva.newTag")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="tag-management-content px-4">
        <Grid container>
          <Grid item xs={12} sm={12} md={5.7} lg={5.7} className="m-4">
            <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
              <Typography variant="bodyMedium" sx={{ marginBottom: 4 }}>
                {Props.isEdit
                 ? t("opva.updateTag")
                 : t("opva.newTag")}
              </Typography>
            </Grid>
            <form onSubmit={(e) => e.preventDefault()} noValidate>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="bodySmall" sx={{ marginBottom: 2 }}>
                  {t("opva.tag")}
                </Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    placeholder={t("opva.enterTagName")}
                    variant="standard"
                    value={formData.name}
                    onChange={handleChange}
                    sx={{
                      marginBottom: 6,
                      "& .MuiInputBase-input": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      } }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mb-2">
                <Typography variant="bodySmall">
                  {t("opva.description")}
                </Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    fullWidth
                    type="text"
                    name="description"
                    variant="standard"
                    placeholder={t("opva.enterDescription")}
                    value={formData.description}
                    onChange={handleChange}
                    sx={{
                      marginBottom: 6,
                      "& .MuiInputBase-input": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      } }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  size="large"
                  variant="contained"
                  href="tags-management"
                  disabled={!isButtonEnabled()}
                  onClick={handleCreateUpdateTag}
                >
                  {Props?.isEdit
                    ? t("opva.updateTag")
                    : t("opva.newTag")}
                </Button>
              </Grid>
            </form>
          </Grid>
          {Props.isEdit ? (
            <></>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={0.2} lg={0.2} className="m-2">
                <div className="user-dividers">
                  <Divider variant="middle" orientation="vertical" flexItem />
                </div>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={5.3}
                lg={5.3}
                className="mt-4"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                    {t("opva.addBulkTags")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                    {t("opva.downloadFormatForBulkTags")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <div
                    className="upload-box-container"
                    onDragOver={handleUploadBoxDragOver}
                    onDrop={handleUploadBoxDrop}
                  >
                    <input
                      type="file"
                      accept=".xlsx"
                      hidden
                      id="user-file-upload"
                      onChange={handleFileChange}
                    />
                    <div className="d-flex align-self-center mb-2">
                      {!selectedFile && (
                        <label
                          htmlFor="user-file-upload"
                          className="file-upload-icon"
                        >
                          <FileUploadOutlinedIcon color="primary" />
                        </label>
                      )}
                      {selectedFile && (
                        <Chip
                          color="primary"
                          sx={{ minWidth: "auto", maxWidth: "250px" }}
                          label={selectedFile.name}
                          title={selectedFile.name}
                          onDelete={handleRemoveClick}
                        />
                      )}
                    </div>
                    <div className="d-flex align-self-center mb-2">
                      <label htmlFor="user-file-upload">
                        <Link
                          variant="bodyNormal"
                          underline="none"
                          className="me-1"
                        >
                          <b>{t("opva.clickHere")}</b>
                        </Link>
                        <Typography variant="bodyNormal">
                          {t("opva.uploadDropMedia")}
                        </Typography>
                      </label>
                    </div>
                  </div>
                  <div style={{ marginLeft: "25px" }}>
                    {errorMsg && (
                      <Typography variant="body1" color="error">
                        {`Error: ${errorMsg}`}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  {t("opva.downloadFormatHere")}
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} className="mb-4">
                  <DownloadExcelButton
                    header={header}
                    defaultValues={defaultValues}
                    listLength={3}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} className="mb-4">
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ color: "#FFFFFFCC" }}
                    onClick={handleBulkUser}
                    disabled={!selectedFile}
                  >
                    {t("opva.addBulkTags")}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};
