import "./OrganisationTeam.scss";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup, Grid, Link, Typography } from "@mui/material";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import {
  GroupListModel,
  OrganisationTeamData,
  UserListModel,
} from "../../models/OrganisationModels";
import { OrganisationGroupsLoading } from "./OrganisationGroupsLoading";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { useTranslation } from "react-i18next";

export const OrganisationTeam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { httpGet, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [organisationTeamData, setOrganisationTeamData] = useState(
    {} as OrganisationTeamData
  );

  const fetchOrganisationTeamData = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_ORGANIZATION_STATS_GROUPS).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          setOrganisationTeamData(response.data);
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchOrganisationTeamData();
  }, []);

  return (
    <div className="organisation-team-container px-5 pt-2">
      <Breadcrumb
        pathList={[
          { name: t("opva.management"), path: "management" },
        ]}
      />
      <Typography variant="h3" className="mb-4">
      {t("opva.management")}
      </Typography>
      <Grid container className="organisation-team mb-4">
        <Grid item xs={12} sm={6} md={4}>
          <div className="team-management">
            <div className="d-flex justify-content-around">
              <div>
                <Typography variant="h5">
                  {t("opva.groupManagement")}
                </Typography>
                <div className="mt-1">
                  <Typography variant="bodySmall">
                    {organisationTeamData.groupsCount || 0} {t("opva.groups")}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Link
                    onClick={(e) => navigate("/group-management")}
                    title={t("opva.manageGroups")}
                  >
                    <Typography variant="bodySmall" className="fw-bold">
                      {t("opva.manageGroups")}
                    </Typography>
                  </Link>
                </div>
              </div>
              <img
                src="images/Group-Management.jpg"
                alt="Group Management Icon"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="ps-3">
          <div className="team-management">
            <div className="d-flex justify-content-around">
              <div>
                <Typography variant="h5">{t("opva.tagsManagement")}</Typography>
                <div className="mt-1">
                  <Typography variant="bodySmall">
                    {organisationTeamData.tagsCount || 0} {t("opva.tags")}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Link
                    onClick={(e) => navigate("/tags-management")}
                    title={t("opva.manageTags")}
                  >
                    <Typography variant="bodySmall" className="fw-bold">
                      {t("opva.manageTags")}
                    </Typography>
                  </Link>
                </div>
              </div>
              <img
                src="images/Tags-Management.jpg"
                alt="Group Management Icon"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="ps-3">
          <div className="team-management">
            <div className="d-flex justify-content-around">
              <div>
                <Typography variant="h5">{t("opva.userManagement")}</Typography>
                <div className="mt-1">
                  <Typography variant="bodySmall">
                    {organisationTeamData.employeesCount || 0} {t("opva.users")}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Link
                    onClick={(e) => navigate("/newmanagement")}
                    title={t("opva.manageUsers")}
                  >
                    <Typography variant="bodySmall" className="fw-bold">
                      {t("opva.manageUsers")}
                    </Typography>
                  </Link>
                </div>
              </div>
              <img
                src="images/User-Management.jpg"
                alt="Group Management Icon"
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Typography variant="h3" className="mb-4">
        {t("opva.latestGroups")}
      </Typography>
      <Grid container spacing={2} className="mb-4">
        {isSpinnerShow ? (
          <OrganisationGroupsLoading />
        ) : (
          <>
            {organisationTeamData?.latestGroups &&
            organisationTeamData.latestGroups.length > 0 ? (
              organisationTeamData.latestGroups.map(
                (data: GroupListModel, index: number) => (
                  <Grid item xs={12} sm={4} md={3} key={index}>
                    <div className="organisation-management">
                      <div className="h-50">
                        <div className="d-flex">
                          <span className="vl-radius"></span>
                          <Typography variant="h6" className="ms-3 ps-1 mb-1">
                            {data.name}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="bodySmall">
                            {data.description}
                          </Typography>
                        </div>
                      </div>
                      <div className="d-flex align-items-center h-50 pt-2">
                        <div className="flex-grow-1 w-50">
                          <div className="mb-1">
                            <Typography variant="bodySmall">
                              {t("opva.teams")}
                            </Typography>
                          </div>
                          {data?.assignedEmployees &&
                          data.assignedEmployees.length > 0 ? (
                            <AvatarGroup
                              max={4}
                              total={data.assignedEmployees.length}
                              className="team-avatar-container float-start"
                              renderSurplus={(surplus) => (
                                <span className="ms-1">
                                  {surplus.toString()[0]}+
                                </span>
                              )}
                            >
                              {data.assignedEmployees.map(
                                (userData: UserListModel, index: number) => (
                                  <Avatar key={"user-icon-" + index} />
                                )
                              )}
                            </AvatarGroup>
                          ) : (
                            <Typography variant="labelSmall">
                              {t("opva.noUserAdded")}
                            </Typography>
                          )}
                        </div>
                        {/* <div className="flex-grow-1 w-50">
                          <div className="mb-1">
                            <Typography variant="bodySmall">
                              Group size
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="bodyNormal">928</Typography>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Grid>
                )
              )
            ) : (
              <Grid item xs={12} md={12} className="p-5">
                <section className="d-flex align-items-center justify-content-center">
                  <Typography variant="labelNormal">
                    {t("opva.noLastestGroups")}
                  </Typography>
                </section>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};
