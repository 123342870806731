import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthenticationContext } from "./Contexts";
import { ProtectedRoute } from "./ProtectedRoute";
import { Home } from "../modules/home/components/Home";
import { Workflow } from "../modules/workflow/components/Workflow";
import { Organisation } from "../modules/organisation/components/Organisation";

import { PlansAndBilling } from "../modules/plans-and-building/components/PlansAndBuilding";
import { ProfileAccount } from "../modules/personal-account/Component/PersonalAccount";
import { NewWorkflowCreation } from "../modules/new-workflow-creation/components/NewWorkflowCreation";
import { LandingPage } from "./components/landing-page/LandingPage";
import Login from "../modules/login/components/Login";
import { NewUser } from "../modules/user-management/components/new-user/NewUser";
import { NewManagementWorkflow } from "../modules/user-management/components/new-management/NewManagement";
import SignUp from "../modules/sign-up/components/signUp/SignUp";
import { Notification } from "../modules/notification/component/notification/Notification";
import { OnboardOrganisation } from "../modules/onboard-organisation/components/OnboardOrganisation";
import RequestDemo from "../modules/request-demo/components/RequestDemo/RequestDemo";
import { SignupRequest } from "../modules/signup-request/components/signup-request/SignUpRequest";
import { Skills } from "../modules/skills/components/skills/Skills";
import { AssignSkills } from "../modules/skills/components/assign-skills/AssignSkills";
import { MapWorkFlow } from "../modules/skills/components/map-workflow/MapWorkFlow";
import { GroupManagement } from "../modules/group-management/components/GroupManagement";
import { NewTag } from "../modules/tags-management/components/new-tag/NewTag";
import { NewGroup } from "../modules/group-management/components/new-group/NewGroup";
import { TagManagement } from "../modules/tags-management/components/tag-management/TagMangement";
import { ManageOrganisation } from "../modules/manage-organisation/components/ManageOrganisation";
import { MobileApplicationInfo } from "../modules/mobile-application-info/components/MobileApplicationInfo";
import { OrganisationTeam } from "../modules/organisation/components/organisation-team/OrganisationTeam";

export const Routers = () => {
  const { token } = useContext(AuthenticationContext);
  return (
    <Routes>
      <Route
        path="/"
        element={
          !token ? (
            <Navigate to="/login" replace />
          ) : (
            <Navigate to="/home" replace />
          )
        }
      />
      <Route
        path="/login"
        element={!token ? <Login /> : <Navigate to="/home" replace />}
      />
      <Route
        path="/sign-up"
        element={!token ? <SignUp /> : <Navigate to="/home" replace />}
      />

      <Route
        path="/request-demo"
        element={!token ? <RequestDemo /> : <Navigate to="/home" replace />}
      />
      <Route
        path="/mobile-app"
        element={
          !token ? <MobileApplicationInfo /> : <Navigate to="/home" replace />
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workflow"
        element={
          <ProtectedRoute>
            <Workflow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/newworkflow"
        element={
          <ProtectedRoute>
            <NewWorkflowCreation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editworkflow"
        element={
          <ProtectedRoute>
            <NewWorkflowCreation isEdit={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/publishworkflow"
        element={
          <ProtectedRoute>
            <NewWorkflowCreation isPublish={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/newuser"
        element={
          <ProtectedRoute>
            <NewUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edituser"
        element={
          <ProtectedRoute>
            <NewUser isEdit={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/newmanagement"
        element={
          <ProtectedRoute>
            <NewManagementWorkflow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organization-profile"
        element={
          <ProtectedRoute>
            <Organisation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/plans&billing"
        element={
          <ProtectedRoute>
            <PlansAndBilling />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfileAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="/management"
        element={
          <ProtectedRoute>
            <OrganisationTeam />
          </ProtectedRoute>
        }
      />
      <Route
        path="/group-management"
        element={
          <ProtectedRoute>
            <GroupManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-group"
        element={
          <ProtectedRoute>
            <NewGroup />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-group"
        element={
          <ProtectedRoute>
            <NewGroup isEdit={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute>
            <Notification />
          </ProtectedRoute>
        }
      />
      <Route
        path="/skill"
        element={
          <ProtectedRoute>
            <Skills />
          </ProtectedRoute>
        }
      />
      <Route
        path="/assign-skill"
        element={
          <ProtectedRoute>
            <AssignSkills />
          </ProtectedRoute>
        }
      />
      <Route
        path="/map-workflow"
        element={
          <ProtectedRoute>
            <MapWorkFlow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/onboard-organisation"
        element={
          <ProtectedRoute>
            <OnboardOrganisation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-organisation"
        element={
          <ProtectedRoute>
            <OnboardOrganisation isEdit={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manage-organisation"
        element={
          <ProtectedRoute>
            <ManageOrganisation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/signup-request"
        element={
          <ProtectedRoute>
            <SignupRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tags-management"
        element={
          <ProtectedRoute>
            <TagManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path="/new-tags"
        element={
          <ProtectedRoute>
            <NewTag />
          </ProtectedRoute>
        }
      />

      <Route
        path="/edit-tags"
        element={
          <ProtectedRoute>
            <NewTag isEdit={true} />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
