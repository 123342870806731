import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Link,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppDispatch, RootState } from "../../../shared/Store";
import "./Workflow.scss";
import { Breadcrumb } from "../../breadcrumb/components/Breadcrumb";
import { Order, TableHeadCell } from "../../../shared/models/TableModels";
import {
  WorkflowConstants,
  WorkflowListModel,
  WorkflowStatusConstants,
} from "../models/Workflow";
import { VersionHistory } from "./version-history/VersionHistory";
import { Assign } from "./assign/Assign";
import { Share } from "./share/Share";
import { TrackProgress } from "./track-progress/TrackProgress";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { tableSorting, updateURLParams } from "../../../shared/utils/Utils";
import { TableLoading } from "../../../shared/components/skeleton/table-loading/TableLoading";
import { setSearchText } from "../../../shared/reducers/HeaderSearchReducer";
import { useWorkflowUtils } from "./WorkflowUtils";
import { AuthenticationContext } from "../../../shared/Contexts";
import { useTranslation } from "react-i18next";

export const Workflow = () => {
  const { t } = useTranslation();
  const { userRole } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const { httpGet, httpPost, httpPut, handleAlertBar, checkRole } = useAPI();
  const { getWorkflowTableHeaderCells } = useWorkflowUtils();
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(WorkflowConstants.PUBLISHED);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [toggleCollapse, setToggleCollapse] = useState<number | null>(null);
  const [collapseText, setCollapseText] = useState<string | null>(null);
  const [workflowDataList, setWorkflowDataList] = useState<WorkflowListModel[]>(
    []
  );
  const [workflowDataVisible, setWorkflowDataVisible] = useState<
    WorkflowListModel[]
  >([]);
  const [perPageRows, setPerPageRows] = useState<number>(10);
  const dispatch: AppDispatch = useDispatch();
  const searchText = useSelector((state: RootState) => state.headerSearch.text);
  const [workflowTableHeaderCells, setWorkflowTableHeaderCells] = useState(
    getWorkflowTableHeaderCells(tabValue)
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPerPageRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    handleCollapseClose();
    setPage(newPage);
  };

  const getCount = (): number => {
    return Math.ceil(workflowDataList.length / perPageRows);
  };

  const defaultWorkflowOptions = (tabValue: WorkflowConstants) => {
    setWorkflowTableHeaderCells(getWorkflowTableHeaderCells(tabValue));
    setSelected([]);
    setOrder("desc");
    setOrderBy("name");
    setPage(0);
    handleCollapseClose();
    setTabValue(tabValue);
    dispatch(setSearchText(""));
  };

  const handleTabChange = (
    event: SyntheticEvent,
    newValue: WorkflowConstants
  ) => {
    defaultWorkflowOptions(newValue);
  };

  const handleSelectAllCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = workflowDataVisible.map(
        (n: WorkflowListModel, index: number) => n.id
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSorting = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleCollapseOpen = (rowIndex: number, collapseFeature: string) => {
    setToggleCollapse(rowIndex);
    setCollapseText(collapseFeature);
  };

  const handleCollapseClose = () => {
    setToggleCollapse(null);
    setCollapseText(null);
  };

  const renderTruncateTitle = (title: string, whereTo: string): string => {
    return title.length > WorkflowConstants.WORKFLOW_TITLE_LENGTH
      ? whereTo === "display"
        ? title.substring(0, WorkflowConstants.WORKFLOW_TITLE_LENGTH) + "..."
        : title
      : whereTo === "display"
      ? title
      : "";
  };

  const toggleDescriptionText = (workflowData: WorkflowListModel) => {
    workflowData.isDescriptionViewMore = !workflowData.isDescriptionViewMore;
    setWorkflowDataVisible([...workflowDataVisible]);
  };

  const renderTruncateDescription = (workflowData: WorkflowListModel) => {
    return !workflowData.isDescriptionViewMore ? (
      <>
        {workflowData.description.substring(
          0,
          WorkflowConstants.WORKFLOW_DESCRIPTION_LENGTH
        )}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(workflowData)}
        >
          {t("opva.viewMore")}
        </Link>
      </>
    ) : (
      <>
        {workflowData.description}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(workflowData)}
        >
          {t("opva.viewLess")}
        </Link>
      </>
    );
  };

  const fetchWorkflowList = (tabValue: WorkflowConstants) => {
    setIsLoading(true);
    let workflowVersionStatus: string | number = tabValue;
    if (
      checkRole([
        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
      ]) &&
      tabValue === WorkflowConstants.IN_REVIEW
    ) {
      workflowVersionStatus = `${WorkflowConstants.IN_REVIEW}, ${WorkflowConstants.WORK_IN_PROGRESS}, ${WorkflowConstants.DRAFT}`;
    } else if (
      checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR]) &&
      tabValue === WorkflowConstants.WORK_IN_PROGRESS
    ) {
      workflowVersionStatus = `${WorkflowConstants.IN_REVIEW}, ${WorkflowConstants.WORK_IN_PROGRESS}`;
    }
    const queryParams = {
      workflowVersionStatus: workflowVersionStatus,
      sortColumn: "name",
      page: page,
      size: 1000,
      isDescending: order === "desc" ? true : false,
      searchString: searchText,
      assignedFilter:
        checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR]) &&
        tabValue === WorkflowConstants.WORK_IN_PROGRESS
          ? true
          : false,
    };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.WORKFLOWS_LIST_BYSTATUS,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          setWorkflowDataList(response.data);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const handleCloneWorkflow = (workflowData: WorkflowListModel) => {
    const urlParams = {
      workflowId: workflowData.id,
      versionId: workflowData?.workflowVersions
        ? workflowData?.workflowVersions[0]?.id
        : 0,
    };
    httpPost(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.CLONE_WORKFLOW, urlParams),
      null
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.workflowClonedSuccessMsg")
            );
            navigate(
              `/editworkflow?workflowId=${workflowData.id}&versionId=${
                response.data?.id || 0
              }`
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleTurnOnOff = (
    event: ChangeEvent<HTMLInputElement>,
    workflowData: WorkflowListModel
  ) => {
    const checked = event.target.checked;
    const urlParams = {
      workflowId: workflowData.id,
    };
    httpPut(
      updateURLParams(
        checked
          ? OPTIVAL_CONSTANTS.API_URLS.ENABLE_WORKFLOW
          : OPTIVAL_CONSTANTS.API_URLS.DISABLE_WORKFLOW,
        urlParams
      ),
      null
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar(
            "error",
            response?.data?.message || t("opva.serviceUnavailable")
          );
        } else {
          workflowData.inactive = !workflowData.inactive;
          handleAlertBar(
            "success",
            checked
              ? t("opva.workflowEnabledSuccessMsg")
              : t("opva.workflowDisabledSuccessMsg")
          );
        }
      },
      (error) => {}
    );
  };

  useMemo(() => {
    if (userRole && userRole.length) {
      setWorkflowTableHeaderCells(getWorkflowTableHeaderCells(tabValue));
    }
  }, [userRole]);

  useMemo(() => {
    const propertyType = ["groupsAssigned", "noOfVersions"].includes(orderBy)
      ? "number"
      : "string";
    const sortedList = tableSorting(
      workflowDataList,
      order,
      orderBy,
      propertyType
    );
    setWorkflowDataList(sortedList);
    setWorkflowDataVisible(
      sortedList.slice(page * perPageRows, page * perPageRows + perPageRows)
    );
  }, [order, orderBy, page, perPageRows, workflowDataList]);

  // Call fetchData on component mount
  useEffect(() => {
    fetchWorkflowList(tabValue);
  }, [tabValue, searchText]);

  return (
    <div className="workflow-container px-4 pt-2">
      <Breadcrumb pathList={[{ name: t("opva.instrukt"), path: "workflow" }]} />
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="fw-bold mb-3">{t("opva.workflow")}</h3>
        {checkRole([
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ADMIN,
        ]) && (
          <Button
            onClick={(e) => navigate("/newworkflow")}
            variant="contained"
            size="large"
            sx={{ width: "18%" }}
          >
            {t("opva.createWorkflow")}
          </Button>
        )}
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          className="tabs-container"
        >
          <Tab
            value={WorkflowConstants.PUBLISHED}
            label={t("opva.published")}
            sx={{ textTransform: "none" }}
          />
          {checkRole([OPTIVAL_CONSTANTS.SUPERVISOR]) && (
            <Tab
              value={WorkflowConstants.WORK_IN_PROGRESS}
              label={t("opva.inProgress")}
              sx={{ textTransform: "none" }}
            />
          )}
          {checkRole([
            OPTIVAL_CONSTANTS.CONTENT_ADMIN,
            OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
            OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          ]) && (
            <Tab
              value={WorkflowConstants.IN_REVIEW}
              label={t("opva.createAssignWorkflow")}
              sx={{ textTransform: "none" }}
            />
          )}
          {checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR]) && (
            <Tab
              value={WorkflowConstants.DRAFT}
              label={t("opva.draft")}
              sx={{ textTransform: "none" }}
            />
          )}
          {checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR]) && (
            <Tab
              value={WorkflowConstants.WORK_IN_PROGRESS}
              label={t("opva.assigned")}
              sx={{ textTransform: "none" }}
            />
          )}
        </Tabs>

        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="Workflow table"
          >
            <TableHead>
              <TableRow key="workflow-table-header">
                {/* <TableCell
                  padding="checkbox"
                  className="header-bg-color"
                  sx={{ width: "3%" }}
                >
                  <Checkbox
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < workflowDataVisible.length
                    }
                    checked={
                      workflowDataVisible.length > 0 &&
                      selected.length === workflowDataVisible.length
                    }
                    onChange={handleSelectAllCheckbox}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                  />
                </TableCell> */}

                {workflowTableHeaderCells.map((headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      orderBy === headCell.sortingProperty ? order : false
                    }
                    align={headCell.position}
                    className="px-2"
                    sx={{
                      width: headCell.width + "%",
                    }}
                  >
                    {headCell.sorting ? (
                      <TableSortLabel
                        active={orderBy === headCell.sortingProperty}
                        direction={
                          orderBy === headCell.sortingProperty ? order : "asc"
                        }
                        onClick={(event) =>
                          handleSorting(headCell.sortingProperty)
                        }
                      >
                        {t(headCell.label)}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      t(headCell.label)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableLoading column={workflowTableHeaderCells.length} />
            ) : (
              <TableBody>
                {workflowDataVisible && workflowDataVisible.length ? (
                  workflowDataVisible.map((data: WorkflowListModel, index) => {
                    const isItemSelected = isSelected(data.id);
                    // const labelId = `table-checkbox-${index}`;

                    return (
                      <Fragment key={index}>
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleSelectCheckbox(event, data.id)
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell className="p-2">
                            <section className="d-flex align-items-center">
                              <img
                                src="images/User-Thumb.svg"
                                alt="User Icon"
                                width={40}
                                height={40}
                              />
                              <div className="w-100 ms-3">
                                <p
                                  className="fw-bold mb-1"
                                  title={renderTruncateTitle(
                                    data.name,
                                    "title"
                                  )}
                                >
                                  {tabValue === WorkflowConstants.PUBLISHED ? (
                                    renderTruncateTitle(data.name, "display")
                                  ) : (
                                    <Link
                                      underline="none"
                                      component="button"
                                      onClick={() =>
                                        handleCollapseOpen(
                                          index,
                                          WorkflowConstants.VERSION_HISTORY
                                        )
                                      }
                                    >
                                      {renderTruncateTitle(
                                        data.name,
                                        "display"
                                      )}
                                    </Link>
                                  )}
                                </p>
                                {tabValue === WorkflowConstants.PUBLISHED && (
                                  <label>Version {data.publishedVersion}</label>
                                )}
                              </div>
                            </section>
                          </TableCell>
                          <TableCell className="p-2">
                            {data.description.length >
                            WorkflowConstants.WORKFLOW_DESCRIPTION_LENGTH
                              ? renderTruncateDescription(data)
                              : data.description}
                          </TableCell>
                          {tabValue === WorkflowConstants.PUBLISHED && (
                            <TableCell className="p-2" align="center">
                              {data.noOfVersions || 0}
                            </TableCell>
                          )}
                          {tabValue === WorkflowConstants.PUBLISHED &&
                            checkRole([OPTIVAL_CONSTANTS.SUPERVISOR]) && (
                              <TableCell className="p-2" align="center">
                                {data.groupsAssigned}
                              </TableCell>
                            )}
                          {tabValue === WorkflowConstants.PUBLISHED &&
                            checkRole([
                              OPTIVAL_CONSTANTS.SUPERVISOR,
                              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                            ]) && (
                              <TableCell className="p-2" align="center">
                                <Link
                                  component="button"
                                  title="Assign"
                                  onClick={() =>
                                    handleCollapseOpen(
                                      index,
                                      WorkflowConstants.ASSIGN
                                    )
                                  }
                                >
                                  <AssignmentReturnOutlinedIcon />
                                </Link>
                              </TableCell>
                            )}
                          {tabValue !== WorkflowConstants.PUBLISHED && (
                            <TableCell className="p-2">
                              {new Date(data.createdAt).toLocaleDateString()}
                            </TableCell>
                          )}
                          {tabValue !== WorkflowConstants.PUBLISHED && (
                            <TableCell className="p-2">
                              {new Date(data.modifiedAt).toLocaleDateString()}
                            </TableCell>
                          )}
                          {tabValue === WorkflowConstants.WORK_IN_PROGRESS &&
                            checkRole([
                              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                              OPTIVAL_CONSTANTS.ADMIN,
                            ]) && (
                              <TableCell className="p-2">
                                {data?.workflowVersions[0]?.status ===
                                  WorkflowConstants.IN_REVIEW && (
                                  <>{t("opva.readyForReview")}</>
                                )}
                                {data?.workflowVersions[0]?.status ===
                                  WorkflowConstants.WORK_IN_PROGRESS && (
                                  <>
                                    {t("opva.inProgress")}
                                  </>
                                )}
                                {data?.workflowVersions[0]?.status ===
                                  WorkflowConstants.DRAFT && (
                                  <>{t("opva.draft")}</>
                                )}
                              </TableCell>
                            )}
                          {tabValue === WorkflowConstants.PUBLISHED && (
                            <TableCell className="p-2" align="center">
                              <Link
                                component="button"
                                title="Share"
                                onClick={() =>
                                  handleCollapseOpen(
                                    index,
                                    WorkflowConstants.SHARE
                                  )
                                }
                              >
                                <ShareIcon />
                              </Link>
                            </TableCell>
                          )}
                          {tabValue === WorkflowConstants.PUBLISHED &&
                            checkRole([
                              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                              OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                            ]) && (
                              <TableCell className="p-2" align="center">
                                <Link
                                  component="button"
                                  title="Copy"
                                  onClick={(e) => handleCloneWorkflow(data)}
                                >
                                  <ContentCopyIcon />
                                </Link>
                              </TableCell>
                            )}
                          {tabValue === WorkflowConstants.PUBLISHED &&
                            checkRole([
                              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                            ]) && (
                              <TableCell className="p-2" align="center">
                                <Switch
                                  checked={!data.inactive}
                                  onChange={(e) => handleTurnOnOff(e, data)}
                                />
                              </TableCell>
                            )}
                          {/* {(tabValue === WorkflowConstants.IN_REVIEW ||
                            tabValue === WorkflowConstants.DRAFT) &&
                            checkRole([
                              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                              OPTIVAL_CONSTANTS.ADMIN,
                              OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                            ]) && (
                              <TableCell className="p-2" align="center">
                                <Link
                                  component="button"
                                  title="Delete"
                                  disabled
                                >
                                  <DeleteIcon color="disabled" />
                                </Link>
                              </TableCell>
                            )} */}

                          {/* <TableCell className="p-2" align="center">
                            <Link component="button">
                              <img
                                src="images/Track-Progress.svg"
                                alt="Track Progress"
                                title="Track Progress"
                                width={15}
                                height={20}
                                onClick={() =>
                                  handleCollapseOpen(
                                    index,
                                    WorkflowConstants.TRACK_PROGRESS
                                  )
                                }
                              />
                            </Link>
                          </TableCell>
                          <TableCell className="p-2" align="center">
                            <Link component="button">
                              <img
                                src="images/More.svg"
                                alt="More"
                                title="More Options"
                                width={15}
                                height={10}
                              />
                            </Link>
                          </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} className="p-0">
                            <Collapse
                              in={index === toggleCollapse}
                              timeout="auto"
                              unmountOnExit
                            >
                              {(collapseText ===
                                WorkflowConstants.VERSION_HISTORY && (
                                <VersionHistory
                                  workflowId={data.id}
                                  workflowData={data}
                                  collapseClose={handleCollapseClose}
                                  switchTabName={(value) =>
                                    defaultWorkflowOptions(
                                      value || WorkflowConstants.PUBLISHED
                                    )
                                  }
                                />
                              )) ||
                                (collapseText === WorkflowConstants.ASSIGN && (
                                  <Assign
                                    workflowId={data.id}
                                    workflowData={data}
                                    collapseClose={handleCollapseClose}
                                  />
                                )) ||
                                (collapseText === WorkflowConstants.SHARE && (
                                  <Share
                                    workflowId={data.id}
                                    workflowData={data}
                                    collapseClose={handleCollapseClose}
                                  />
                                )) ||
                                (collapseText ===
                                  WorkflowConstants.TRACK_PROGRESS && (
                                  <TrackProgress
                                    workflowId={data.id}
                                    collapseClose={handleCollapseClose}
                                  />
                                ))}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      {t("opva.noWorkflowDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <div className="d-flex align-items-center justify-content-end">
            <TablePagination
              component="div"
              className="pagination-container mb-4"
              count={workflowDataList.length}
              page={page}
              rowsPerPage={perPageRows}
              onPageChange={handlePaginationChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </Box>
    </div>
  );
};
