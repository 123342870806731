import "./DashboardInfo.scss";
import { DashboardInfoProps } from "../../models/DashboardInfoProps";
import { useTranslation } from "react-i18next";

export const DashboardInfo = (props: DashboardInfoProps) => {
  const { t } = useTranslation(); 
  return (
    <div className={"dashboard-info-container " + props.bgColorName}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column w-75">
          <label className="title-text">{t(props.title)}</label>
          <label className="name-text">
            <b>
              {props.infoName}&nbsp;
              {props.infoSubName ? (
                <span className="sub-name-text">{props.infoSubName}</span>
              ) : null}
            </b>
          </label>
        </div>
        <img src={"images/" + props.iconName} alt="Profile"></img>
      </div>
    </div>
  );
};
