import { useTranslation } from "react-i18next";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { TableHeadCell } from "../../../../shared/models/TableModels";
import { useAPI } from "../../../../shared/services/api/API";

export const useSkillsUtils = () => {
  const { checkRole } = useAPI();
  const { t } = useTranslation();
  const getSkillzHeaderCell = (): TableHeadCell[] => {
    const tableHeadCell: TableHeadCell[] = [];
    tableHeadCell.push(
      {
        id: "skillsName",
        label: t("opva.skillName"),
        sorting: true,
        sortingProperty: "name",
        width: 15,
        position: "left",
      },
      {
        id: "description",
        label: t("opva.description"),
        sorting: true,
        sortingProperty: "description",
        width: 25,
        position: "left",
      },
      {
        id: "createdBy",
        label: t("opva.createdBy"),
        sorting: true,
        sortingProperty: "createdBy",
        width: 12,
        position: "left",
      },
      {
        id: "userAssigned",
        label: t("opva.userAssigned"),
        sorting: true,
        sortingProperty: "usersAssigned",
        width: 15,
        position: "center",
      }
    );
    if (
      checkRole([
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
        OPTIVAL_CONSTANTS.CONTENT_EDITOR,
      ])
    ) {
      tableHeadCell.push({
        id: "mapWorkflow",
        label: t("opva.mapWorkflow"),
        sorting: false,
        sortingProperty: "",
        width: 12,
        position: "center",
      });
    }
    if (
      checkRole([
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SUPERVISOR,
      ])
    ) {
      tableHeadCell.push({
        id: "assign",
        label: t("opva.assign"),
        sorting: false,
        sortingProperty: "",
        width: 10,
        position: "center",
      });
    }
    if (
      checkRole([
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        OPTIVAL_CONSTANTS.SUPER_ADMIN,
        OPTIVAL_CONSTANTS.SUPERVISOR,
        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
      ])
    ) {
      tableHeadCell.push({
        id: "edit",
        label: t("opva.edit"),
        sorting: false,
        sortingProperty: "",
        width: 10,
        position: "center",
      });
    }
    // if (
    //   checkRole([
    //     OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
    //     OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
    //     OPTIVAL_CONSTANTS.SUPER_ADMIN,
    //   ])
    // ) {
    //   tableHeadCell.push({
    //     id: "delete",
    //     label: "Delete",
    //     sorting: false,
    //     sortingProperty: "",
    //     width: 10,
    //     position: "center",
    //   });
    // }
    return tableHeadCell;
  };
  return { getSkillzHeaderCell };
};
