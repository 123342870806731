import { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { remove } from "lodash";
import "./Assign.scss";
import {
  CollapseRowProps,
  WorkflowAssignGroupsModel,
  WorkflowConstants,
  WorkflowListModel,
} from "../../models/Workflow";
import { AssignLoading } from "./AssignLoading";
import { useAPI } from "../../../../shared/services/api/API";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useTranslation } from "react-i18next";

export const Assign = (props: CollapseRowProps) => {
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, httpPost, httpDelete, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [groupsList, setGroupsList] = useState<WorkflowListModel[]>([]);
  const [assignedGroupsList, setAssignedGroupsList] = useState<
    WorkflowAssignGroupsModel[]
  >([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);
  const [searchGroupName, setSearchGroupName] = useState<string>("");
  const [groupId, setGroupId] = useState<number>(0);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const { t } = useTranslation(); 
  const handleAlertDialogOpen = (groupId: number) => {
    setGroupId(groupId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setGroupId(0);
    setIsAlertDialog(false);
  };

  const handleRemoveAssignedGroup = () => {
    const urlParams = {
      groupId: groupId,
      workflowId: props.workflowId,
    };
    httpDelete(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.REMOVE_ASSIGNED_WORKFLOW_GROUPS,
        urlParams
      )
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        } else {
          handleAlertBar(
            "success",
            t("opva.assignedWorkflowGroupRemovedSuccessfully.")
          );
          updateAssignedGroupsList();
          updateAssignedGroupsData(-1);
          handleAlertDialogClose();
        }
      },
      (error) => {}
    );
  };

  const handleAddWorkflowToGroups = () => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      workflowId: props.workflowId,
    };
    const queryParams = {
      groupIds: selectedGroupId,
    };
    httpPost(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.ASSIGN_WORKFLOW_TO_GROUPS,
        urlParams
      ),
      null,
      queryParams
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          const errorMsg =
            response?.data?.status === 400
              ? OPTIVAL_CONSTANTS.ERROR_CODE_MSG
                  .WORKFLOW_ALREADY_ASSIGNED_TO_GROUP
              : t("opva.serviceUnavailable");
          handleAlertBar("error", errorMsg);
        } else {
          handleAlertBar(
            "success",
            t("opva.workflowAssignedGroupSuccessfully.")
          );
          setSearchGroupName("");
          setSelectedGroupId(0);
          updateAssignedGroupsData(1);
          getAssignedGroups(props.workflowId);
        }
      },
      (error) => {}
    );
  };

  function getAssignedGroups(workflowId: number) {
    const urlParams = {
      workflowId: workflowId,
    };
    httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.WORKFLOW_ASSIGNED_GROUPS,
        urlParams
      )
    ).then(
      (response) => {
        if (response && response.data) {
          setAssignedGroupsList(response.data);
        }
      },
      (error) => {}
    );
  }

  function updateAssignedGroupsData(count: number) {
    if (
      props.workflowData &&
      (props.workflowData?.groupsAssigned ||
        props.workflowData?.groupsAssigned > -1)
    ) {
      props.workflowData.groupsAssigned += count;
    }
  }

  function updateAssignedGroupsList() {
    remove(assignedGroupsList, function (group: WorkflowAssignGroupsModel) {
      return group.id === groupId;
    });
  }

  useEffect(() => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "name",
      isDescending: true,
      searchString: "",
    };
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_GROUP_DATA, queryParams, false).then(
      (response) => {
        if (response && response.data) {
          setGroupsList(response.data);
        }
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    getAssignedGroups(props.workflowId);
  }, [props.workflowId]);

  return (
    <div className="assign-container">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
            {t("opva.assignToGroups")}
          </Typography>
          <div className="d-flex">
            <Autocomplete
              id="search-by-group"
              className="me-5 w-50"
              freeSolo
              options={groupsList}
              renderOption={(props, option: WorkflowListModel) => (
                <li {...props} value={option.id}>
                  {option.name}
                </li>
              )}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Regular option
                return option.name;
              }}
              value={searchGroupName}
              onChange={(event: any, newValue) => {
                if (newValue && typeof newValue === "object") {
                  setSelectedGroupId(newValue.id);
                }
              }}
              onInputChange={(event: any, newInputValue: string) => {
                setSearchGroupName(newInputValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  placeholder={t("opva.searchByGroups")}
                  className="search-name-text pe-0"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              size="medium"
              className="py-2 w-25"
              onClick={handleAddWorkflowToGroups}
              disabled={!selectedGroupId}
            >
              {t("opva.addGroup")}
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" className="fw-bold mt-4 mb-3">
        {t("opva.assignToGroups")}
      </Typography>
      <Grid container spacing={2} className="assigned-user-container">
        {isSpinnerShow ? (
          <AssignLoading />
        ) : (
          <>
            {assignedGroupsList && assignedGroupsList.length ? (
              assignedGroupsList.map(
                (data: WorkflowAssignGroupsModel, index: number) => (
                  <Grid item xs={3} md={3} key={index}>
                    <section className="assigned-user">
                      <div className="d-flex mb-3">
                        <img src="images/User-Thumb-Black.svg" alt="User" />
                        <div className="ms-2">
                          <Typography
                            variant="bodySmall"
                            className="d-block fw-bold"
                          >
                            {data.name}
                          </Typography>
                          <Typography variant="labelSmall" className="d-block">
                            {t("opva.totalUsers")} <b>{data.usersAssigned}</b>
                          </Typography>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Typography variant="labelSmall">
                        {t("opva.currentProgress")}
                        </Typography>
                        <Typography variant="labelSmall" className="float-end">
                          <b>{data.progress}%</b>
                        </Typography>
                      </div>
                      <div className="linear-progress-container mb-4">
                        <LinearProgress
                          variant="determinate"
                          value={data.progress || 0}
                        />
                      </div>
                      <div className="d-flex justify-content-around">
                        <Link component="button" title={t("opva.notify")}>
                          <NotificationsNoneIcon />
                        </Link>
                        <div className="vertical-line"></div>
                        <Link
                          component="button"
                          title={t("opva.delete")}
                          onClick={() => handleAlertDialogOpen(data.id)}
                        >
                          <DeleteOutlineIcon color="error" />
                        </Link>
                      </div>
                    </section>
                  </Grid>
                )
              )
            ) : (
              <Grid item xs={12} md={12}>
                <section className="d-flex align-items-center justify-content-center assigned-user h-100">
                  <Typography variant="labelNormal">
                  {t("opva.noAssignedGroups")}
                  </Typography>
                </section>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <AlertDialog
        open={isAlertDialog}
        title={t("opva.deleteAssignGroup")}
        description={t("opva.deleteAssignGroupMsg")}
        submitBtnText={t("opva.delete")}
        handleSubmit={handleRemoveAssignedGroup}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
    </div>
  );
};
